export const environment = {
  production: true,
  apiConstants: {
    AZURE_B2C_AUTHORITY: 'https://jhhusprod.b2clogin.com/tfp/jhhusprod.onmicrosoft.com/B2C_1A_SignUpOrSignInWithPhoneOrEmail',
    AZURE_B2C_CLIENT_ID: '2c3d38b3-5ce4-4531-80ad-baee8f7c0bf1',
    AZURE_B2C_SCOPE: 'https://jhhusprod.onmicrosoft.com/8d978a7c-b09c-4222-8db0-b90d797bb466/user_impersonation',
    AZURE_B2C_RESETPASSWORD: 'https://jhhusprod.b2clogin.com/tfp/jhhusprod.onmicrosoft.com/B2C_1A_PASSWORDRESETEMAIL',
    GATEWAY_BASE_URL: 'https://gateway.usprod.jawbonehealth.com',
    USER_V2_API_URL: '/user/api/v0.2',
    HEALTHCLOUD_API_URL: '/healthcloud/api/v0.1',
    CUSTOMER_TENANT: '',
    DEMO: false,
  }
};
